// Stolen from https://github.com/bukacekd/lock-scroll/tree/main.
// Normally I'd use it from npm, but it's a pretty new package from an unknown person, don't want it disappearing.
// Plus, it's simple enough that a local copy might be better anyway.
const lockElements = new WeakSet()

const isBodyOrHTML = (target) =>
  target === document.body || target === document.documentElement

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

const getTarget = (target) => {
  if (target == undefined) {
    return document.documentElement
  }

  if (target instanceof HTMLElement) {
    return target
  }

  throw TypeError('First argument must be HTMLElement')
}

let scrolloptions = {}

export const lockScroll = (target) => {
  const el = getTarget(target)

  if (lockElements.has(el)) {
    return
  }

  if (isIOS && isBodyOrHTML(el)) {
    scrolloptions = {
      left: window.scrollX,
      top: window.scrollY,
      behavior: 'instant',
    }
    el.style.cssText = `left: -${scrolloptions.left}px; position: fixed; top: -${scrolloptions.top}px;`
  } else {
    const scrollbarWidth =
      (isBodyOrHTML(el) ? window.innerWidth : el.offsetWidth) - el.clientWidth
    el.style.cssText = `overflow: hidden; padding-inline-end: ${scrollbarWidth}px`
  }

  lockElements.add(el)
}

export const unlockScroll = (target) => {
  const el = getTarget(target)
  el.style.cssText = ''

  isIOS && isBodyOrHTML(el) && document.documentElement.scrollTo(scrolloptions)
  lockElements.delete(el)
}
